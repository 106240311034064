import * as React from "react";
import Layout, { NoiseFilter } from "../components/layout";
import Block from "../components/block";
import styled from "styled-components";
import { BREAKPOINTS, HEADING_HEIGHT } from "../consts";
import { useMediaQuery } from "react-responsive";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Gradient } from "../components/gradients";
import { ARTICLE } from "../gradient-config";

export const query = graphql`
  query BlogQuery($slug: String!) {
    allMarkdownRemark(
      limit: 4
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { published: { eq: true } } }
    ) {
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          title
          date(formatString: "LL")
          coverImage {
            childImageSharp {
              gatsbyImageData(
                placeholder: NONE
              )
            }
          }
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      id
      frontmatter {
        title
        articleHeaderImage {
          childImageSharp {
            gatsbyImageData(
              placeholder: NONE
            )
          }
        }
        mobileArticleHeaderImage {
          childImageSharp {
            gatsbyImageData(
              placeholder: NONE
            )
          }
        }
        date(formatString: "LL")
      }
    }
  }
`;

const Page = styled.div``;

const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;;
  justify-content: center;
  align-items: center;
  height: 20.5rem;
  width: 100%;
  padding-top: ${HEADING_HEIGHT};
  box-sizing: border-box;
  z-index: 5;
  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    text-align: center;
    padding: 0 1rem;
    height: 16rem;
    h1 {
      margin: 1rem 0 0;
    }
  }
`;

const ArticleHeaderImage = styled.div`
  max-width: 43.6363rem;
  margin: 4rem auto;
  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    margin: 2rem auto;
  }
`;

const ContentSection = styled.div`
  background-color: #F1F2F4;
  width: 100%;
  > article {
    max-width: 31.5rem;
    margin: 0 auto;
    color: #151718;
    text-align: left;
    .gatsby-img-attributes {
      margin: 0 auto;
    }
    .gatsby-resp-image-wrapper {
      margin: 3rem auto;
    }
    p {
      font-size: 20px;
      line-height: 1.5em;
      letter-spacing: -1%;
      font-weight: 500;
    }

    a {
      color: #151718;
      text-decoration: underline;
    }
  }
  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    padding: 0 1rem;
    > article {
      p {
        font-size: 16px;
      }
    }
  }
`;

const ArticlesContainer = styled.div`
  h2 {
    text-align: center;
  }
`;
const ArticlesBoxes = styled.div`
  padding-top: 0.8rem;
  margin-bottom: 2rem;
  display: flex;
  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
const ArticlesBox = styled.div`
  padding: 1rem;
  width: 33%;
  > a {
    > span {
      display: block;
      &.date {
        padding-top: .5rem;
      }
    }
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    width: 94%;
    margin: 0 0 2rem 0;
    padding: 0;
  }
`;

const OtherArticles = styled.div`
  max-width: 43.6363rem;
  margin: 4rem auto 6rem;
  color: #151718;
  a {
    color: #151718;
  }
`;

const PostPage = ({ data: { markdownRemark, allMarkdownRemark }, children }) => {
  const [isMobile, setIsMobile] = React.useState(false);
  const [gradients, setGradients] = React.useState({});

  const mediaQuery = useMediaQuery({
    query: `(max-width: ${BREAKPOINTS["mobile"]}px)`,
  });

  React.useEffect(() => {
    setIsMobile(mediaQuery);
    setGradients(mediaQuery ? ARTICLE.MOBILE : ARTICLE.DESKTOP);
  }, [mediaQuery, isMobile]);

  return (
    <Page>
      <Layout>
        <Block noPadding={true} fullWidth={true}> 
          <Gradient gradient={gradients[1]} />
          <HeaderSection>
            <span className="f-m-m">{markdownRemark.frontmatter.date}</span>
            <h1 className="f-4xl">{markdownRemark.frontmatter.title}</h1>
          </HeaderSection>
          <NoiseFilter/>
          <Gradient gradient={gradients[2]} />
        </Block>
        <Block noPadding={true} fullWidth={true}>
          <ContentSection>
            <ArticleHeaderImage>
              {/* {isMobile ? 
                 markdownRemark.frontmatter.mobileArticleHeaderImage &&
                  <GatsbyImage
                    image={getImage(markdownRemark.frontmatter.mobileArticleHeaderImage)}
                    alt={markdownRemark.frontmatter.title}
                    quality={100}
                    placeholder="none"
                  />
                :
                markdownRemark.frontmatter.articleHeaderImage &&
                  <GatsbyImage
                    image={getImage(markdownRemark.frontmatter.articleHeaderImage)}
                    alt={markdownRemark.frontmatter.title}
                    quality={100}
                    placeholder="none"
                  />
                
              } */}
          
            </ArticleHeaderImage>
            <article dangerouslySetInnerHTML={{ __html: markdownRemark.html }}>
            </article>
            <OtherArticles>
              <ArticlesContainer>
                <h2 className="f-3xl">Other Articles</h2>
                <ArticlesBoxes>
                  {allMarkdownRemark?.nodes.map((article, key) => {

                    if (markdownRemark.id === article.id) {
                      return null;
                    }

                    return (
                      <ArticlesBox key={key}>
                        <Link className="article-card" to={article.fields.slug}>
                        <GatsbyImage
                          image={{...getImage(article.frontmatter.coverImage), width: 870, height: 600}}
                          alt={article.frontmatter.title}
                          quality={100}
                          placeholder="none"
                          objectFit="cover"
                          imgStyle={{borderRadius: 25}}
                        />
                        <span className="date f-m-m">
                          {article.frontmatter.date}
                        </span>
                        
                          <span className="title hover-effect light f-l-b">
                            {article.frontmatter.title}
                          </span>
                        </Link>
                      </ArticlesBox>
                    );
                  })}
                </ArticlesBoxes>
                <div style={{ textAlign: "center" }}>
                  <Link to="/blog/">
                    <button className="f-m-b invert">View all</button>
                  </Link>
                </div>
              </ArticlesContainer>


            </OtherArticles>
          </ContentSection>
        </Block>
      </Layout>
    </Page>
  );
};

export default PostPage;
